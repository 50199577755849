/* Author:

*/

$(function(){

	//初回動作
	if($('body.works').length){
		$('#menu').click(function(event){
			if($('#globalNav').hasClass('open')){
				$('#globalNav').removeClass('open');
			}else{
				$('#globalNav').addClass('open');
			}
			event.stopPropagation();
		});
		$('body').click(function(event){
			$('#globalNav').removeClass('open');
		});
	}

	//colorbox
	$('a[href$=".jpg"],a[href$=".jpeg"],a[href$=".png"],a[href$=".gif"]','#numImages').each(function(){
		var title = [];
		if($(this).children('img').attr('alt')) title.push('<h1>'+$(this).children('img').attr('alt')+'</h1>');
		if($(this).attr('title')) title.push('<p>'+$(this).attr('title')+'</p>');
		if($(this).attr('size'))title.push('<p>'+$(this).attr('size')+'</p>');
		if($(this).attr('material'))title.push('<p>'+$(this).attr('material')+'</p>');
		if($(this).attr('finish')) title.push('<p>'+$(this).attr('finish')+'</p>');
		if(title.length>0) title.unshift('<div id="cboxTitleInner">'); title.push('</div>');
		$(this).colorbox({title:title.join(''),rel:"gallery"});
	});
	$('#imagefield_large>a,#imagefield_sub_a>a,#imagefield_sub_b>a').each(function(num){
		$(this).click(function(){
			$('#n'+(num+1)+'>a').trigger('click'); return false;
		});
	});
});
